import { ProductTenor } from '@protos/product';
import { ContractRisk, ProductRisk } from '@protos/trading';

export const transformRiskResponse = (
  productRisk: ProductRisk,
  tenors: ProductTenor[]
): { data: ContractRisk[]; version?: number; timestamp?: string } => {
  const monthlyTenors = tenors.filter(tenor => tenor.frequency === 'monthly').slice(0, 12);
  const dataTemplate = monthlyTenors.map(
    tenor => ({ tenorDisplay: tenor.display.toLocaleUpperCase(), tenor: 0, cmeTenor: 0, iceTenor: 0, tenorCode: tenor.code } as ContractRisk)
  );

  if (!productRisk) return { data: dataTemplate };

  const riskResponseSymbols = productRisk.tenors.map(tenor => tenor.symbol);

  return {
    version: productRisk.version,
    timestamp: productRisk.timestamp,
    data: dataTemplate.map(template => {
      const code = template.tenorCode;

      if (riskResponseSymbols.includes(`${productRisk.product_symbol}${code}`)) {
        const tenorRisk = productRisk.tenors.find(tenor => tenor.symbol === `${productRisk.product_symbol}${code}`);
        if (tenorRisk) {
          return {
            ...template,
            tenor: Number(tenorRisk.value),
            cmeTenor: Number(tenorRisk.value_cme),
            iceTenor: Number(tenorRisk.value_ice),
          } as ContractRisk;
        }
      }

      return template;
    }),
  };
};

export const transformParams = (params: any, schema: Record<string, any>) => {
  const { properties } = params;
  if (!properties) return params;

  const transformedParams = Object.entries(properties).reduce((acc, [key, value]) => {
    const { allOf, ...restValue } = value as Record<string, any>;
    if (value && allOf && allOf.length) {
      const newValue = allOf.reduce((acc, item) => {
        const schemaProperty = item.$ref.split('/').pop();
        const schemaValue = schema[schemaProperty];
        if (schemaValue && schemaValue.enum) acc.push(...schemaValue.enum);
        return acc;
      }, []);

      return { ...acc, [key]: { ...restValue, enum: newValue } };
    }

    return { ...acc, [key]: value };
  }, {});

  return { ...params, properties: transformedParams, required: Object.keys(properties) };
};
