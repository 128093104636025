import { Box } from '@mui/material';
import { stream } from '@services/context';
import theme from '@shared/themes/darkTheme';

export const unhandledErrorMessage =
  'An unexpected error has occurred. We apologise for the inconvenience and are working on a fix. Please refresh the page and try again.';

const AppErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const streamError = stream.getSocketError();
  if (streamError) {
    return (
      <Box
        minHeight="100vh"
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color={theme.palette.text.disabled}
      >
        Too many connections. You can only have 5 tabs open.
      </Box>
    );
  }
  return children;
};

export default AppErrorBoundary;
