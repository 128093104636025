import { useDataLoader } from '@hooks/useDataLoader';
import { Box, Collapse, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { News } from '@protos/news';
import { IconComponent } from '@shared/components/IconComponent';
import theme from '@shared/themes/darkTheme';
import { DataApi } from '@utils/data';
import { getDateTimeDifference, toLocaleISOString } from '@utils/date';
import { useCallback, useEffect, useState } from 'react';
import '../news/news.css';

interface NewsListProps {
  dataLoader: DataApi<News>;
}

const getNewsTitle = (item: News) => {
  if (item.title) {
    return item.title.length > 250 ? `${item.title.substring(0, 250)}...` : item.title;
  } else if (item.summary) {
    return item.summary.length > 250 ? `${item.summary.substring(0, 250)}...` : item.summary;
  }

  return '';
};

const FLUX_AI_URL = 'https://fluxaiapi.azurewebsites.net/api/FluxAiAPI?code=jGllGm50FXRRbpZgXxTCVx4IWgsvSxkOmtlrNlh6VhvnAzFu3w3OdQ==';

export const NewsList = ({ dataLoader }: NewsListProps) => {
  const currentDateTime = Date.now();
  const { data, simpleFetchData } = useDataLoader(dataLoader);

  const [isLoadingNews, setIsLoadingNews] = useState<boolean>(true);
  const [newsItems, setNewsItems] = useState<News[]>([]);
  const [fluxAIArticle, setFluxAIArticle] = useState<{ timestamp: string; summary: string; title: string }>();
  const [flashingItems, setFlashingItems] = useState<News[]>([]);
  const [isFluxAiOpen, setIsFluxAiOpen] = useState<boolean>(false);

  const fetchFluxAI = useCallback(() => {
    fetch(FLUX_AI_URL)
      .then(response => response.json())
      .then(data => {
        const AIDataItem = { ...data, title: 'Flux AI' };

        if (!fluxAIArticle) {
          setFluxAIArticle(AIDataItem);
          return;
        } else if (fluxAIArticle.timestamp !== AIDataItem.timestamp) {
          setFluxAIArticle(AIDataItem);
          setFlashingItems([...flashingItems, AIDataItem]);
        }
      });
  }, [flashingItems, fluxAIArticle]);

  useEffect(() => {
    simpleFetchData();
    fetchFluxAI();
  }, []);

  useEffect(() => {
    if (newsItems.length === 0) {
      const sortedNewsData = data.sort((a: News, b: News) => new Date(b.published).getTime() - new Date(a.published).getTime());
      setNewsItems(sortedNewsData);
    } else {
      const uniqueNewItems = data.filter((item: News) => !newsItems.some((i: any) => i.url === item.url));
      const sortedUniqueItems = uniqueNewItems.sort((a: News, b: News) => new Date(b.published).getTime() - new Date(a.published).getTime());
      setFlashingItems(sortedUniqueItems);
      setNewsItems([...sortedUniqueItems, ...newsItems]);
    }
    setIsLoadingNews(false);
  }, [data, simpleFetchData]);

  useEffect(() => {
    const interval = setInterval(() => {
      simpleFetchData();
    }, 1000 * 30);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const aiInterval = setInterval(() => {
      fetchFluxAI();
    }, 1000 * 60);

    return () => clearInterval(aiInterval);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFlashingItems([]);
    }, 5000);

    return () => clearTimeout(timer);
  }, [flashingItems]);

  return isLoadingNews ? (
    <Typography sx={{ display: 'flex', flex: 1, alignSelf: 'center', justifySelf: 'center' }}>Loading news...</Typography>
  ) : newsItems.length > 0 ? (
    <List
      component="nav"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'auto',
        padding: 0,
        margin: 0,
        marginLeft: -1,
        marginRight: -1,
      }}
    >
      {fluxAIArticle && (
        <Stack
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            backgroundColor: theme.palette.background.main,
            borderBottom: `1px solid white`,
          }}
        >
          <ListItemButton
            onClick={() => setIsFluxAiOpen(!isFluxAiOpen)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              backgroundColor: theme.palette.background.buy,
              '&:hover': {
                backgroundColor: theme.palette.background.buy,
              },
              '&:active': {
                backgroundColor: theme.palette.background.buy,
              },
              '&:focus': {
                backgroundColor: theme.palette.background.buy,
              },
            }}
          >
            <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
              <Box display="flex" gap={1}>
                <IconComponent name="ri-robot-3-fill" />
                <Typography
                  sx={{
                    fontSize: 11,
                    fontWeight: 800,
                  }}
                >
                  {fluxAIArticle.title}
                </Typography>
              </Box>

              <Box display="flex" gap={1}>
                {isFluxAiOpen ? <IconComponent name="ri-arrow-drop-up-line" /> : <IconComponent name="ri-arrow-drop-down-line" />}
                <Typography
                  sx={{
                    fontSize: 11,
                  }}
                >
                  {getDateTimeDifference(currentDateTime, toLocaleISOString(new Date(fluxAIArticle.timestamp + 'Z')))}
                </Typography>
              </Box>
            </Stack>
          </ListItemButton>
          <Collapse in={isFluxAiOpen} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
            <List component="div" disablePadding sx={{ zIndex: 10 }}>
              <ListItem
                key="flux-ai-article"
                className={flashingItems.find(elem => elem.title === 'Flux AI') ? 'flashing' : ''}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
              >
                <Typography
                  sx={{
                    fontSize: 11,
                    fontWeight: 800,
                    textAlign: 'justify',
                  }}
                >
                  {fluxAIArticle.summary}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: 10,
                    marginTop: 1,
                  }}
                >
                  Flux AI can make mistakes. Check important info.
                </Typography>
              </ListItem>
            </List>
          </Collapse>
        </Stack>
      )}

      {newsItems.map((item: News) => (
        <ListItem
          key={`${item.title}-${item.url}-${item.published}`}
          className={flashingItems.find(elem => elem.url === item.url) ? 'flashing' : ''}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
            flexWrap: 'wrap',
            color: theme.palette.text.secondary,
            '&:nth-of-type(odd)': {
              bgcolor: theme.palette.background.lighter,
            },
            '&:hover': {
              bgcolor: theme.palette.action.hover,
              color: theme.palette.text.primary,
            },
            paddingTop: 0.2,
            paddingBottom: 0,
          }}
          onClick={() => window.open(item.url, '_blank')}
        >
          <Stack display="flex" flexDirection="row" width="100%" justifyContent="space-between">
            <Typography
              sx={{
                fontSize: 10,
              }}
            >
              {item.news_feed.name}
            </Typography>
            <Typography
              sx={{
                fontSize: 10,
              }}
            >
              {getDateTimeDifference(currentDateTime, item.published)}
            </Typography>
          </Stack>
          <ListItemText
            primary={getNewsTitle(item)}
            primaryTypographyProps={{ fontSize: 11, fontWeight: 800, textAlign: 'justify' }}
            sx={{ flex: 3, color: theme.palette.text.primary, textAlign: 'left', width: '100%' }}
          />
        </ListItem>
      ))}
    </List>
  ) : (
    <Typography sx={{ display: 'flex', flex: 1, alignSelf: 'center', justifySelf: 'center' }}>No News available, try again later</Typography>
  );
};
