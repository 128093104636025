import { useApi } from '@hooks/useApi';
import { ChartSymbol } from '@protos/charts';
import { addYears } from 'date-fns';
import { BarData, Time } from 'lightweight-charts';
import { useCallback, useMemo, useState } from 'react';
import { SeasonYearsToFetch } from './seasonalChartConsts';
import { generateAllHistoricSymbols } from './seasonalChartUtils';

export const useSeasonalCharts = (chartFormula: string, isFormulasEnabled: boolean) => {
  const { apiClient } = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [chartData, setChartData] = useState<Record<string, BarData<Time>[]>>({});
  const [selectedFormula, setSelectedFormula] = useState<string>(chartFormula?.toLocaleLowerCase());
  const [isUsingFormulas, setIsUsingFormulas] = useState(isFormulasEnabled);
  const [isUsingRollingInFormula, setIsUsingRollingInFormula] = useState(false);

  const isDataAvailable = useMemo(() => !isLoading && Object.values(chartData).some(data => data.length), [chartData, isLoading]);

  const getSymbolMetaData = useCallback(
    async (symbol: string) => {
      if (!apiClient) return;

      try {
        const symbolMetaData = await apiClient.getSymbol(symbol);
        setHasError(false);
        return symbolMetaData;
      } catch (error) {
        setHasError(true);
        return null;
      }
    },
    [apiClient]
  );

  const getAllHistoricSymbols = useCallback(
    async (symbol: string) => {
      if (!apiClient) return;

      const symbolMetaData = await getSymbolMetaData(symbol);
      if (!symbolMetaData) return;

      const { product_symbol, tenor_code, tenor_year } = symbolMetaData;
      const allHistoricSymbols = generateAllHistoricSymbols(product_symbol, tenor_code, tenor_year);

      return allHistoricSymbols;
    },
    [apiClient, getSymbolMetaData]
  );

  const getShiftingChartData = useCallback(
    async (symbolMetaData: ChartSymbol) => {
      if (!apiClient) return;

      const allHistoricSymbols = await getAllHistoricSymbols(symbolMetaData.symbol);
      if (!allHistoricSymbols?.length) return;
      const apisToCall = allHistoricSymbols.map(historicSymbol => ({ ...historicSymbol, loader: apiClient.chartLoader(historicSymbol.name, '1d') }));

      return Promise.allSettled(apisToCall.map(({ loader }) => loader.loadData())).then(results => {
        if (!results) return {};

        const resultingChartData: Record<string, BarData<Time>[]> = {};

        results.forEach((result, index) => {
          const data = result.status === 'fulfilled' ? result.value : [];
          const { name, shift } = apisToCall[index];

          resultingChartData[name] = data
            .map(
              ({ timestamp, low, high, open, close }) =>
                ({
                  time: addYears(new Date(timestamp), shift).getTime() / 1000,
                  low,
                  high,
                  open,
                  close,
                } as BarData<Time>)
            )
            .reverse();
        });

        return resultingChartData;
      });
    },
    [apiClient, getAllHistoricSymbols]
  );

  const getChartData = useCallback(
    async (symbolMetaData: ChartSymbol) => {
      if (!apiClient) return;

      const { symbol } = symbolMetaData;
      const api = apiClient.chartLoader(symbol, '1d');
      const apiData = await api?.loadData();

      const transformedData = apiData?.reduce((acc, elem) => {
        const { timestamp, low, high, open, close } = elem;
        const timestampDate = new Date(timestamp);
        const currentYear = new Date().getFullYear();
        const key = `${symbol}-${timestampDate.getFullYear()}`;
        const timeShift = currentYear - timestampDate.getFullYear();

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].unshift({
          time: addYears(timestampDate, timeShift).getTime() / 1000,
          low,
          high,
          open,
          close,
        } as BarData<Time>);

        return acc;
      }, {} as Record<string, BarData<Time>[]>);

      return transformedData ?? {};
    },
    [apiClient]
  );

  const getSeasonalChartData = useCallback(
    async (symbolMetaData: ChartSymbol) => {
      const { description } = symbolMetaData;
      const isRollingSymbol = description?.toLowerCase().includes('rolling');

      if (isRollingSymbol) {
        return getChartData(symbolMetaData);
      } else {
        return getShiftingChartData(symbolMetaData);
      }
    },
    [getChartData, getShiftingChartData]
  );

  const onQuerySymbol = useCallback(
    async (symbol: string) => {
      const symbolMetaData = await getSymbolMetaData(symbol);
      if (!symbolMetaData) return;

      const responseData = await getSeasonalChartData(symbolMetaData);
      if (!responseData) return;

      setChartData(responseData);
      if (isLoading) setIsLoading(false);
    },
    [getSymbolMetaData, getChartData, getShiftingChartData, isLoading]
  );

  const onFormulaQuery = useCallback(
    async (formula: string, symbolsInFormula: string[], hasRollingSymbolInFormula: boolean) => {
      if (!apiClient) return;

      setSelectedFormula(formula);
      setIsUsingRollingInFormula(hasRollingSymbolInFormula);

      if (hasRollingSymbolInFormula) {
        const transformedFormula = symbolsInFormula
          .reduce((acc, symbol) => {
            return acc.replaceAll(`#${symbol}`, `{${symbol}}`);
          }, formula)
          .replace(/\s/g, '');
        const responseData = await apiClient.calculateFormula(transformedFormula);

        const transformedData = responseData.reduce((acc, elem) => {
          const { timestamp, low, high, open, close, symbol } = elem;
          const timestampDate = new Date(timestamp);
          const currentYear = new Date().getFullYear();
          const key = `${symbol}-${timestampDate.getFullYear()}`;
          const timeShift = currentYear - timestampDate.getFullYear();

          if (!acc[key]) {
            acc[key] = [];
          }

          acc[key].unshift({
            time: addYears(timestampDate, timeShift).getTime() / 1000,
            low,
            high,
            open,
            close,
          } as BarData<Time>);

          return acc;
        }, {} as Record<string, BarData<Time>[]>);

        setChartData(transformedData);
      } else {
        const allHistoricSymbols = await Promise.allSettled(symbolsInFormula.map(symbol => getAllHistoricSymbols(symbol)));
        const transformedFormulas = Array.from({ length: SeasonYearsToFetch }).map(() => ({ formula, shift: 0 }));

        allHistoricSymbols.forEach(result => {
          if (result.status === 'fulfilled') {
            const { value } = result;
            if (!value) return;
            const baseSymbolInFormula = value[0].name;

            value.forEach(({ name, shift }, index) => {
              const newFormula = transformedFormulas[index].formula.replaceAll(`#${baseSymbolInFormula}`, `{${name}}`);
              transformedFormulas[index].formula = newFormula;
              if (transformedFormulas[index].shift < shift) {
                transformedFormulas[index].shift = shift;
              }
            });
          }
        });

        const responseData = await Promise.allSettled(transformedFormulas.map(({ formula }) => apiClient.calculateFormula(formula)));

        const transformedData = responseData.reduce((acc, result, index) => {
          if (result.status === 'fulfilled') {
            const { value } = result;
            if (!value) return acc;

            const { shift } = transformedFormulas[index];
            value.forEach(elem => {
              const { timestamp, low, high, open, close, symbol } = elem;
              const timestampDate = new Date(timestamp);

              if (!acc[symbol]) {
                acc[symbol] = [];
              }

              const newTimeStampDate = addYears(timestampDate, shift).getTime() / 1000;
              const isNewTimestampYearInFuture = new Date(newTimeStampDate * 1000).getFullYear() > new Date().getFullYear();
              if (isNewTimestampYearInFuture) return;

              acc[symbol].unshift({
                time: newTimeStampDate,
                low,
                high,
                open,
                close,
              } as BarData<Time>);
            });
          }

          return acc;
        }, {} as Record<string, BarData<Time>[]>);

        setChartData(transformedData);
      }

      if (isLoading) setIsLoading(false);
    },
    [isLoading, apiClient]
  );

  const onFormulasEnabledChange = useCallback((isChecked: boolean) => {
    setIsUsingFormulas(isChecked);
    setChartData({});
    setIsLoading(true);
  }, []);

  return {
    isDataAvailable,
    isLoading,
    isUsingFormulas,
    chartData,
    selectedFormula,
    hasError,
    isUsingRollingInFormula,
    onQuerySymbol,
    onFormulaQuery,
    onFormulasEnabledChange,
  };
};
