import { useAdminApi } from '@hooks/useAdminApi';
import { Box, MenuItem, Select, Skeleton, Stack, Typography, styled } from '@mui/material';
import { TradingGroup } from '@protos/trading';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ExecutionVolumeData, useExecutionVolumeGrids } from './useExecutionVolumeGrids';

interface ExecutionVolumePeriodSelection {
  otc: 'daily' | 'hourly';
  otc_client: 'daily' | 'hourly';
  exchange: 'daily' | 'hourly';
}

const initialExecutionVolumeData: ExecutionVolumeData = {
  otc: { data: [], isLoading: true },
  otc_client: { data: [], isLoading: true },
  exchange: { data: [], isLoading: true },
};

const initialExecutionVolumePeriodSelection: ExecutionVolumePeriodSelection = {
  otc: 'daily',
  otc_client: 'daily',
  exchange: 'daily',
};

const StyledChartBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
}));

const StyledNoDataBox = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
}));

const PeriodOptions = ['daily', 'hourly'];
export const TradingExecutionVolume = () => {
  const { apiClient } = useAdminApi();

  const [executionVolumeData, setExecutionVolumeData] = useState<ExecutionVolumeData>(initialExecutionVolumeData);
  const [selectedExecutionVolumePeriods, setSelectedExecutionVolumePeriods] =
    useState<ExecutionVolumePeriodSelection>(initialExecutionVolumePeriodSelection);

  const { chartContainerRef, otcChartContainerRef, otcClientChartContainerRef, exchangeChartContainerRef } =
    useExecutionVolumeGrids(executionVolumeData);
  const GraphsToRender: { title: string; key: keyof ExecutionVolumePeriodSelection; ref: React.MutableRefObject<HTMLDivElement | undefined> }[] =
    useMemo(
      () => [
        { title: 'OTC', key: 'otc', ref: otcChartContainerRef },
        { title: 'OTC CLIENT', key: 'otc_client', ref: otcClientChartContainerRef },
        { title: 'EXCHANGE', key: 'exchange', ref: exchangeChartContainerRef },
      ],
      []
    );

  const fetchExectionVolumeDataForGroup = useCallback(
    async (group: TradingGroup, period: 'daily' | 'hourly') => {
      if (!apiClient) return;
      try {
        const data = await apiClient?.getExecutionVolumeForGroup(group, period);

        if (data) {
          const ascTimeSeriesData = data.reverse();
          setExecutionVolumeData(prev => ({ ...prev, [group]: { data: ascTimeSeriesData, isLoading: false } }));
        }
      } catch (error) {
        console.error(`Error fetching execution volume for trading group: ${group}`, error);
        setExecutionVolumeData(prev => ({ ...prev, [group]: { data: [], isLoading: false } }));
      }
    },
    [apiClient]
  );

  useEffect(() => {
    setExecutionVolumeData(prev => ({ ...prev, otc: { data: [], isLoading: true } }));
    fetchExectionVolumeDataForGroup('otc', selectedExecutionVolumePeriods.otc);
  }, [selectedExecutionVolumePeriods.otc, fetchExectionVolumeDataForGroup]);

  useEffect(() => {
    setExecutionVolumeData(prev => ({ ...prev, otc_client: { data: [], isLoading: true } }));
    fetchExectionVolumeDataForGroup('otc_client', selectedExecutionVolumePeriods.otc_client);
  }, [selectedExecutionVolumePeriods.otc_client, fetchExectionVolumeDataForGroup]);

  useEffect(() => {
    setExecutionVolumeData(prev => ({ ...prev, exchange: { data: [], isLoading: true } }));
    fetchExectionVolumeDataForGroup('exchange', selectedExecutionVolumePeriods.exchange);
  }, [selectedExecutionVolumePeriods.exchange, fetchExectionVolumeDataForGroup]);

  return (
    <Stack ref={chartContainerRef} height="100%" gap={1}>
      {GraphsToRender.map(({ title, key, ref }) => (
        <Stack display="flex" key={key} height="100%">
          <Box display="flex" justifyContent="space-between" padding={0.5} paddingRight={0} alignItems="center">
            <Typography variant="h6">{title}</Typography>
            <Select
              key={key}
              size="small"
              autoWidth
              style={{ fontSize: 11, maxHeight: 23, fontWeight: 700, borderRadius: 0 }}
              value={selectedExecutionVolumePeriods[key]}
              onChange={(event: any) => setSelectedExecutionVolumePeriods(prev => ({ ...prev, [key]: event.target.value }))}
              tabIndex={-1}
            >
              {PeriodOptions.map(option => (
                <MenuItem key={option} value={option} sx={{ fontSize: 11 }}>
                  {option.toLocaleUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {executionVolumeData[key].isLoading ? (
            <Skeleton variant="rectangular" height="100%" />
          ) : !executionVolumeData[key].data ? (
            <StyledNoDataBox>
              <Typography variant="caption">Data Unavaiable</Typography>
            </StyledNoDataBox>
          ) : (
            <StyledChartBox ref={ref} />
          )}
        </Stack>
      ))}
    </Stack>
  );
};
