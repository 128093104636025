import { Grid } from '@features/dashboard/widgets/grid';
import { Box, Card, CardContent, Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import { Snapshot } from '@protos/snapshots';
import { ContractPNL, ContractRisk } from '@protos/trading';
import { priceFormatterService } from '@services/PriceFormatterService';
import theme from '@shared/themes/darkTheme';
import { formatDatetime } from '@utils/date';
import { useState } from 'react';
import { Bar, BarChart, LabelList, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useTradingKillSwitch } from '../../hooks/useTradingKillSwitch';
import { useTradingPeriodicPnl } from '../../hooks/useTradingPeriodicPnl';
import { TRADING_ADMIN_PNL_BLOTTER_COLUMNS, TRADING_ADMIN_RISK_BLOTTER_COLUMNS } from '../../tradingAdminProtos';
import { KillSwitchButton } from '../KillSwitchButton';

const getRowId = ({ data }): string => data?.tenorCode;
const getPnlRowId = ({ data }): string => data?.period;

const getVarianceBG = (number: string) => {
  const percentage = +number.replace('%', '');

  switch (true) {
    case percentage >= 60 && percentage < 80:
      return '#EFC94C';
    case percentage >= 80 && percentage < 100:
      return '#E27A3F';
    case percentage >= 100:
      return '#DF4949';
    default:
      return 'none';
  }
};

interface TradingRiskChartAndMetaProps {
  data: ContractRisk[];
  metaData?: { version: number; timestamp: string };
  snapshot?: Snapshot;
  isMobileLandscape: boolean;
}

export const TradingRiskChartAndMeta: React.FC<TradingRiskChartAndMetaProps> = ({ data, metaData, snapshot, isMobileLandscape }) => {
  const { killSwitch, onToggleKillSwitch } = useTradingKillSwitch();
  const { pnlData } = useTradingPeriodicPnl();

  const [barProps, setBarProps] = useState<Record<string, string | boolean>>({ tenor: true, exchange: false, hover: '' });

  const onLegendMouseOver = event => {
    if (barProps[event.dataKey]) {
      setBarProps({ ...barProps, hover: event.dataKey });
    }
  };

  const onLegendMouseLeave = event => {
    setBarProps({ ...barProps, hover: '' });
  };

  const onLegendClick = event => {
    setBarProps({ ...barProps, [event.dataKey]: !barProps[event.dataKey], hover: '' });
  };

  return (
    <Box display="flex" flex={1} width="100%" minHeight="70%">
      {!isMobileLandscape && (
        <>
          <Stack width="80%" height="100%" justifyContent="center">
            {metaData && (
              <Stack>
                <Typography
                  style={{ fontSize: 11, fontWeight: 800, alignSelf: 'start', marginLeft: 10, marginTop: 5 }}
                >{`Version: ${metaData.version}`}</Typography>
                <Typography style={{ fontSize: 11, fontWeight: 800, alignSelf: 'start', marginLeft: 10, marginTop: 5 }}>{`Date Time: ${formatDatetime(
                  metaData.timestamp
                )}`}</Typography>
              </Stack>
            )}
            <ResponsiveContainer width="100%" height="70%">
              {data.length ? (
                <BarChart
                  height={100}
                  data={data}
                  margin={{
                    top: 0,
                    right: 20,
                    left: -30,
                    bottom: 40,
                  }}
                  barSize={50}
                >
                  <XAxis dataKey="tenorDisplay" stroke="#fff" tick={{ fontWeight: '800' }} tickMargin={10} />
                  <YAxis
                    stroke="#fff"
                    tick={{ fontWeight: '800' }}
                    tickCount={10}
                    domain={([dataMin, dataMax]) => {
                      const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax));
                      return [-absMax - 100, absMax + 100];
                    }}
                  />
                  <Tooltip
                    contentStyle={{ backgroundColor: theme.palette.background.darker, border: 'none' }}
                    itemStyle={{ fontWeight: 800 }}
                    cursor={{ fill: theme.palette.background.darker, fillOpacity: 0.5 }}
                  />
                  <Legend
                    onMouseOver={onLegendMouseOver}
                    onMouseLeave={onLegendMouseLeave}
                    onClick={onLegendClick}
                    cursor="auto"
                    verticalAlign="top"
                    height={50}
                  />
                  <ReferenceLine y={0} stroke="#778ca3" />
                  <Bar
                    dataKey="tenor"
                    fill="#FFFFFF"
                    name="Total"
                    fillOpacity={Number(barProps.hover === 'tenor' || !barProps.hover ? 1 : 0.6)}
                    hide={!barProps['tenor']}
                    radius={[10, 10, 0, 0]}
                  >
                    <LabelList
                      dataKey="tenor"
                      position="top"
                      style={{ fill: 'white', fontSize: '125%', fontWeight: '800' }}
                      offset={3}
                      formatter={(value: number) => (!value ? '' : value)}
                    />
                  </Bar>
                  {data.some(data => data.cmeTenor) && (
                    <Bar
                      dataKey="cmeTenor"
                      fill="#FFC312"
                      name="CME"
                      fillOpacity={Number(barProps.hover === 'cmeTenor' || !barProps.hover ? 1 : 0.6)}
                      hide={!barProps['cmeTenor']}
                      radius={[10, 10, 0, 0]}
                    >
                      <LabelList
                        dataKey="cmeTenor"
                        position="top"
                        style={{ fill: 'white', fontSize: '125%', fontWeight: '800' }}
                        offset={3}
                        formatter={(value: number) => (!value ? '' : value)}
                      />
                    </Bar>
                  )}
                  {data.some(data => data.iceTenor) && (
                    <Bar
                      dataKey="iceTenor"
                      fill="#CD6133"
                      name="ICE"
                      fillOpacity={Number(barProps.hover === 'iceTenor' || !barProps.hover ? 1 : 0.6)}
                      hide={!barProps['iceTenor']}
                      radius={[10, 10, 0, 0]}
                    >
                      <LabelList
                        dataKey="iceTenor"
                        position="top"
                        style={{ fill: 'white', fontSize: '125%', fontWeight: '800' }}
                        offset={3}
                        formatter={(value: number) => (!value ? '' : value)}
                      />
                    </Bar>
                  )}
                </BarChart>
              ) : (
                <></>
              )}
            </ResponsiveContainer>
          </Stack>
          <Divider orientation="vertical" />
        </>
      )}
      <Stack width={!isMobileLandscape ? '30%' : '100%'} height="100%">
        <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center" marginBottom={3} marginTop={3}>
          {killSwitch ? (
            <>
              <FormControlLabel
                control={
                  <KillSwitchButton
                    sx={{ m: 1, right: -12 }}
                    checked={killSwitch.active}
                    onChange={(event, checked) => onToggleKillSwitch(event, checked)}
                  />
                }
                label={`Kill Switch: ${killSwitch.active ? 'On' : 'Off'}`}
              />
              <Typography style={{ fontSize: 11, fontWeight: 800, textAlign: 'center' }}>
                Last triggered by {!killSwitch.user.full_name ? killSwitch.user.id : killSwitch.user.full_name} at{' '}
                {formatDatetime(killSwitch.timestamp)}
              </Typography>
            </>
          ) : (
            'Loading Kill Switch...'
          )}
        </Box>
        <Box display="flex" flex={1} minHeight={130} width="100%">
          <Card
            variant="outlined"
            sx={{
              width: '100%',
              bgcolor: snapshot
                ? getVarianceBG(priceFormatterService.formatVariancePercentage(snapshot.json_data.var, snapshot.json_data.max_var))
                : 'none',
              borderRadius: 0,
            }}
          >
            <CardContent style={{ padding: 25, marginTop: 13 }}>
              <Typography variant="h6">VaR %</Typography>
              <Typography variant="h3">
                {snapshot ? priceFormatterService.formatVariancePercentage(snapshot.json_data.var, snapshot.json_data.max_var) : '...'}
              </Typography>
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{ width: '100%', borderRadius: 0 }}>
            <CardContent style={{ padding: 25, marginTop: 13 }}>
              <Typography variant="h6">Max VaR</Typography>
              <Typography variant="h3">{snapshot ? priceFormatterService.formatVariance(snapshot.json_data.max_var) : '...'}</Typography>
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{ width: '100%', borderRadius: 0 }}>
            <CardContent style={{ padding: 25, marginTop: 13 }}>
              <Typography variant="h6">VaR</Typography>
              <Typography variant="h3">{snapshot ? priceFormatterService.formatVariance(snapshot.json_data.var) : '...'}</Typography>
            </CardContent>
          </Card>
        </Box>
        <Grid<ContractRisk>
          rowData={data}
          columnDefs={TRADING_ADMIN_RISK_BLOTTER_COLUMNS}
          getRowId={getRowId}
          tooltipShowDelay={0}
          enableCellTextSelection
          defaultColDef={{ enableCellChangeFlash: true }}
        />
        <Divider />
        <Grid<ContractPNL>
          rowData={pnlData}
          columnDefs={TRADING_ADMIN_PNL_BLOTTER_COLUMNS}
          getRowId={getPnlRowId}
          tooltipShowDelay={0}
          enableCellTextSelection
          defaultColDef={{ enableCellChangeFlash: true }}
          autoSizeStrategy={{ type: 'fitGridWidth' }}
          maxHeight="40%"
        />
      </Stack>
    </Box>
  );
};
