import { streamRFQ } from '@services/context';
import { TickerCallback } from '@services/streamRFQService';
import { useCallback, useEffect, useRef } from 'react';

// hook for subscribing to a rfqSymbol (`symbol-size`)
export const useStreamRFQ = (callback: TickerCallback, rfqSymbolToSubscribe: string, selectedExchange: string) => {
  const target = useRef<TickerCallback>(callback);

  const unsubscribeSymbol = useCallback(
    (symbolToUnsibscribe: string) => {
      if (symbolToUnsibscribe) {
        streamRFQ.unsubscribe(symbolToUnsibscribe, selectedExchange, target.current);
      }
    },
    [selectedExchange]
  );

  useEffect(() => {
    if (!rfqSymbolToSubscribe) return;
    target.current = callback;

    streamRFQ.subscribe(rfqSymbolToSubscribe, selectedExchange, callback);
  }, [rfqSymbolToSubscribe, selectedExchange]);

  return { unsubscribeSymbol };
};
