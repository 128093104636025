import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { streamDashboard } from '@services/context';
import { ErrorButton } from '@shared/components/Button';

type DeleteButtonProps = {
  id: string;
  variant?: 'button' | 'link';
  onWidgetClose?: () => void;
};

type NavButtonProps = ButtonProps & {
  invert?: boolean;
  component?: any;
  to?: string;
};

const StyledErrorButton = styled(ErrorButton)(({ theme }) => ({
  height: '34px',
  '& span': {
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const StyledMenuButton = styled(Button, { shouldForwardProp: propName => propName !== 'invert' })<NavButtonProps>(({ theme }) => ({
  padding: 0,
  height: 'unset',
  minWidth: 'unset',
  color: theme.palette.text.secondary,
  '&:hover': {
    background: 'none!important',
    color: theme.palette.text.primary,
  },
}));

export default function DeleteButton({ id, variant = 'button', onWidgetClose }: DeleteButtonProps) {
  const { deleteWidget } = streamDashboard;

  const handleClick = () => {
    if (onWidgetClose) onWidgetClose();
    deleteWidget(id);
  };

  return variant === 'link' ? (
    <StyledMenuButton onClick={handleClick} className="draggable-cancel-area" id="remove-widget-btn" aria-label="Remove Widget" variant="text">
      <span className="ri-close-line" style={{ fontSize: '20px' }} />
    </StyledMenuButton>
  ) : (
    <StyledErrorButton onClick={handleClick} text="Delete" />
  );
}
