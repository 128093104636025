import { useApi } from '@hooks/useApi';
import { useStreamOrders } from '@hooks/useStreamOrders';
import { gridUpdateService } from '@services/GridUpdateService';
import { priceFormatterService } from '@services/PriceFormatterService';
import { streamDashboard } from '@services/context';
import { BlotterWidget } from '@shared/components/BotterWidget';
import { Order } from '@shared/protos/trading';
import { GridApi } from 'ag-grid-community';
import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import { TRADING_BLOTTER_COLUMNS } from './tradingBlotterTypes';

const getRowId = ({ data }): string => data?.id?.toString();
const OTC_BOT_USER_ID = '627377000000793050';

const defaultColDef = {
  sortable: true,
  filter: false,
  floatingFilter: false,
  resizable: true,
  width: 150,
  enableCellChangeFlash: true,
};

interface TradingBlotterGridProps {
  searchTerm: string;
  widgetId: string;
}

export const TradingBlotterGrid = ({ searchTerm, widgetId }: TradingBlotterGridProps) => {
  const { apiClient } = useApi();
  const { editWidget } = streamDashboard;
  const dataLoader = apiClient?.ordersLoader();
  let blotterApi: GridApi<Order>;

  const onGetGridApi = useCallback((gridApi: GridApi<Order>) => {
    if (gridApi) {
      blotterApi = gridApi;
    }
  }, []);

  const debouncedEditWidget = debounce(editWidget, 2000);

  useEffect(() => {
    const interval = setInterval(() => {
      const newRow: any = gridUpdateService.updatedRow;
      if (newRow) {
        const isRowInGrid = !!blotterApi.getRowNode(newRow.id.toString());
        if (isRowInGrid) return;

        blotterApi.applyTransaction({ add: [newRow] });
        gridUpdateService.setUpdatedRow(undefined);

        setTimeout(() => {
          blotterApi.flashCells({ rowNodes: [newRow], fadeDuration: 2000 });
        }, 200);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    debouncedEditWidget(widgetId, {
      payload: {
        searchTerm,
      },
    });
  }, [searchTerm, widgetId, editWidget]);

  useStreamOrders((order: Order) => {
    if (blotterApi) {
      const isRowInGrid = !!blotterApi.getRowNode(order.id.toString());
      const transformedOrder: Order = {
        ...order,
        amount: priceFormatterService.removeTrailZeros(order.amount),
        executed_amount: order.executed_amount ? priceFormatterService.removeTrailZeros(order.executed_amount) : undefined,
        price: order.price ? priceFormatterService.removeTrailZeros(order.price) : undefined,
        executed_avg_price: order.executed_avg_price ? priceFormatterService.removeTrailZeros(order.executed_avg_price) : undefined,
      };

      if (isRowInGrid) {
        blotterApi.applyTransaction({ update: [transformedOrder] });
      } else if (transformedOrder.user.id !== OTC_BOT_USER_ID) {
        const transactionResult = blotterApi.applyTransaction({ add: [transformedOrder] });

        setTimeout(() => {
          const newRowNode = transactionResult?.add[0];
          if (!newRowNode) return;

          blotterApi.flashCells({ rowNodes: [newRowNode], fadeDuration: 2000 });
        }, 500);
      }
    }
  }, !!dataLoader);

  if (!dataLoader) return null;

  return (
    <BlotterWidget
      getGridApi={onGetGridApi}
      dataLoader={dataLoader}
      columnDefs={TRADING_BLOTTER_COLUMNS}
      getRowId={getRowId}
      defaultColDef={defaultColDef}
      animateRows={true}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      tooltipShowDelay={0}
      enableCellTextSelection
      externalSearchValue={searchTerm}
    />
  );
};
