import { useAdminApi } from '@hooks/useAdminApi';
import { useStreamOrders } from '@hooks/useStreamOrders';
import { Order } from '@protos/trading';
import { priceFormatterService } from '@services/PriceFormatterService';
import { BlotterWidget } from '@shared/components/BotterWidget';
import { GridApi } from 'ag-grid-community';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TRADING_ADMIN_ORDER_COLUMNS, TRADING_ADMIN_ORDER_FILTERS } from '../tradingAdminProtos';

const getRowId = ({ data }): string => data?.id?.toString();

const defaultColDef = {
  sortable: true,
  filter: false,
  floatingFilter: false,
  resizable: true,
  width: 150,
  enableCellChangeFlash: true,
};

interface TradingOrdersProps {
  hideFilters?: boolean;
  accountId?: string;
}
export const TradingOrders = ({ hideFilters = false, accountId }: TradingOrdersProps) => {
  const { apiClient } = useAdminApi();
  const navigate = useNavigate();
  const dataLoader = apiClient?.ordersLoader(accountId ? { account_id: accountId } : undefined);

  const [blotterApi, setBlotterApi] = useState<GridApi<Order | null>>();
  const [isReadyToSubscribe, setIsReadyToSubscribe] = useState<boolean>(false);

  const onRowClicked = useCallback(({ data }) => {
    const id = data?.id;
    navigate(`/admin/trading/executions?order_id=${id}`);
  }, []);

  const onGetGridApi = useCallback((gridApi: GridApi<Order | null>) => {
    if (gridApi) {
      setBlotterApi(gridApi);
    }
  }, []);

  const onDataRendered = useCallback(() => {
    setIsReadyToSubscribe(true);
  }, []);

  const onStreamOrder = useCallback(
    (order: Order) => {
      if (!blotterApi) return;

      const isRowInGrid = !!blotterApi.getRowNode(order.id.toString());
      const transformedOrder: Order = {
        ...order,
        amount: priceFormatterService.removeTrailZeros(order.amount),
        executed_amount: order.executed_amount ? priceFormatterService.removeTrailZeros(order.executed_amount) : undefined,
        price: order.price ? priceFormatterService.removeTrailZeros(order.price) : undefined,
        executed_avg_price: order.executed_avg_price ? priceFormatterService.removeTrailZeros(order.executed_avg_price) : undefined,
      };
      blotterApi.applyTransaction(isRowInGrid ? { update: [transformedOrder] } : { add: [transformedOrder] });

      setTimeout(() => {
        if (!isRowInGrid) {
          const newRowNode = blotterApi.getRowNode(order.id.toString());
          if (newRowNode) blotterApi.flashCells({ rowNodes: [newRowNode], fadeDuration: 2000 });
        }
      }, 200);
    },
    [blotterApi]
  );

  useStreamOrders(onStreamOrder, isReadyToSubscribe);

  if (!dataLoader) return null;

  return (
    <BlotterWidget
      getGridApi={onGetGridApi}
      dataLoader={dataLoader}
      columnDefs={TRADING_ADMIN_ORDER_COLUMNS}
      getRowId={getRowId}
      defaultColDef={defaultColDef}
      onRowClicked={onRowClicked}
      onFirstDataRendered={onDataRendered}
      animateRows={true}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      {...(!hideFilters && { filters: TRADING_ADMIN_ORDER_FILTERS })}
    />
  );
};
